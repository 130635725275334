//@flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import type Node from 'react'

import Modal from '../../Modal'
import ModalBody from '../../Modal/ModalBody'
import ModalCloseButton from '../../Modal/ModalCloseButton'
import ModalHead from '../../Modal/ModalHead'
import ModalText from '../../Modal/ModalText'
import ModalRow from '../../Modal/ModalRow'
import ModalButtons from '../../Modal/ModalButtons'
import Radio from '../../Radio'
import Button from '../../Button'

import { useOverflow } from '../../../hooks'
import { useThemeColor } from '../../../hooks'

type Props = {
  isOpen: boolean,
  onApply: boolean => void,
  onClose: () => void,
  preselectPublic: boolean,
}

const SelectChatroomModal = (props: Props): Node => {
  const { isOpen, onClose, onApply, preselectPublic } = props
  const { t } = useTranslation('Chatroom')
  useOverflow()
  const themeColor = useThemeColor()
  const [isPublic, setPublic] = useState(preselectPublic)
  const handleChangePublic = e => setPublic(JSON.parse(e.target.value))

  return (
    <>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <ModalHead title={t('SelectChatroomTitle')} />
        <ModalCloseButton onClose={onClose} />
        <ModalBody
          style={{
            '--themeColor': themeColor,
          }}
        >
          <ModalText>{t('SelectChatroomQuestion')}</ModalText>
          <ModalRow>
            <Radio
              id='internal'
              name='select_chat'
              value={false}
              checked={!isPublic}
              label={t('InternalChatroom')}
              onChange={handleChangePublic}
            />
          </ModalRow>
          <ModalRow>
            <Radio
              id='external'
              name='select_chat'
              value={true}
              checked={isPublic}
              label={t('ExternalChatroom')}
              onChange={handleChangePublic}
            />
          </ModalRow>
        </ModalBody>
        <ModalButtons>
          <Button.Save onClick={() => onApply(isPublic)}>
            {t('Apply')}
          </Button.Save>
          <Button.Cancel onClick={onClose}>{t('Cancel')}</Button.Cancel>
        </ModalButtons>
      </Modal>
    </>
  )
}

export default SelectChatroomModal
