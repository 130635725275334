// @flow

import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import classnames from 'classnames'
import moment from 'moment'
import { withTranslation } from 'react-i18next'
import linkifyHtml from 'linkifyjs/html'
import { difference, map, flow, partialRight } from 'lodash-es'

import { getUser } from '../../../utils/commonSelectors'
import MessageAttachments from '../MessageAttachments'
import HideableMessagePart from './HideableMessagePart'
import { SimpleLike as Like } from '../../Like'
import { getMsgParts } from './helpers'
import { MESSAGE_DIVIDER } from './constants'
import { cutText, isStaffUser, getUserName } from '../../../utils/utils'
import { newLineToBr } from '../../../utils/text'
import SelectCustom from '../../Select/SelectCustom'
import SelectChatroomModal from './SelectChatroomModal'

import styles from './ChatroomMessage.module.scss'

class ChatroomMessage extends PureComponent {
  state = { horizontalScroll: false, modal: null }

  componentDidMount = () => this.setMessageOverscroll()

  componentDidUpdate = () => this.setMessageOverscroll()

  handleDeleteFile =
    ({ id }) =>
    () => {
      const {
        message: { files, uuid },
      } = this.props

      const getFileds = flow([
        partialRight(map, 'id'),
        partialRight(difference, [id]),
      ])

      const filteredFileIds = getFileds(files)

      this.props.showDeleteFilesPopup(uuid, filteredFileIds, id)
    }

  handleDeleteMessage = () => {
    const {
      message: { uuid, files },
    } = this.props

    const deletingFileIds = map(files, 'id')

    this.props.showDeleteMessagePopup(uuid, deletingFileIds)
  }

  handleConvertToRequest = () => {
    this.setState(prevState => ({
      modal: (
        <SelectChatroomModal
          isOpen={!prevState.modal}
          preselectPublic={this.props.isPublic}
          onApply={this.confirmConvertToRequest}
          onClose={() => this.setState({ modal: null })}
        />
      ),
    }))
  }

  confirmConvertToRequest = isPublic => {
    this.setState({ modal: null })
    const {
      message: { uuid },
    } = this.props
    this.props.showConvertToRequestPopup(uuid, isPublic)
  }

  handleAttachToRequest = () => {
    this.setState(prevState => ({
      modal: (
        <SelectChatroomModal
          isOpen={!prevState.modal}
          preselectPublic={this.props.isPublic}
          onApply={this.confirmAttachToRequest}
          onClose={() => this.setState({ modal: null })}
        />
      ),
    }))
  }

  confirmAttachToRequest = isPublic => {
    this.setState({ modal: null })
    const {
      message: { uuid },
    } = this.props
    this.props.showAttachToRequestPopup(uuid, isPublic)
  }

  getName = user => {
    const { isMyMessage, message, t } = this.props

    if (isMyMessage) {
      return t('Me')
    }

    return getUserName(user || message)
  }

  setMessageOverscroll = () => {
    if (!this.textContainer) {
      return
    }

    const {
      textContainer: { scrollWidth, clientWidth },
      state: { horizontalScroll },
    } = this

    if (scrollWidth > clientWidth && !horizontalScroll) {
      this.setState({ horizontalScroll: true })
    }
  }

  handleLike = () => {
    const {
      onLike,
      message: {
        uuid,
        like: { liked: isRemove },
      },
    } = this.props
    const isLike = true
    onLike(uuid, isLike, isRemove)
  }

  handleDislike = () => {
    const {
      onLike,
      message: {
        uuid,
        like: { disliked: isRemove },
      },
    } = this.props
    const isLike = false
    onLike(uuid, isLike, isRemove)
  }

  getOptions = () => {
    const { message, user, outbound } = this.props

    const options = [
      {
        value: 'convertToRequest',
        label: this.props.t('ConvertToRequest'),
        handler: () => this.handleConvertToRequest(),
        icon: 'request',
      },
      {
        value: 'attachToRequest',
        label: this.props.t('AttachToRequest'),
        handler: () => this.handleAttachToRequest(),
        icon: 'pin',
      },
      {
        value: 'selectWorker',
        label: this.props.t('DeleteMessage'),
        handler: () => this.handleDeleteMessage(),
        icon: 'bin',
      },
    ]

    if (outbound || !isStaffUser(user)) {
      options.splice(0, 2)
    } else {
      if (message.created_requests?.length) {
        options.shift()
      }
    }

    if (!message.permissions.can_delete) {
      options.pop()
    }

    return options
  }

  render() {
    const {
      t,
      toggleMessage,
      isMyMessage,
      members,
      message,
      message: {
        created,
        files,
        text,
        owner,
        selected,
        uuid,
        like: {
          like_count: likeCount,
          dislike_count: dislikeCount,
          liked,
          disliked,
        },
        permissions: { can_edit: canEdit, can_delete: canDelete },
      },
      messageOpened,
      locale,
      user,
      scope,
      object,
      outbound,
    } = this.props

    const { horizontalScroll, modal } = this.state

    const msgParts = getMsgParts(text, MESSAGE_DIVIDER)

    const author = members.find(
      member => member.user === owner || member.owner === owner
    )
    const inOutClassName = `messages__item--${isMyMessage ? 'out' : 'in'}`

    const format = locale === 'en' ? 'hh:mm A' : 'HH:mm'
    const time = moment(created).format(format)
    const msgClassName = classnames('messages__item ', inOutClassName, {
      'messages__item--checked': selected,
    })

    const avatar = author
      ? author.avatar || author.avatar_obj.thumbnail
      : message.avatar
    const name = this.getName(author)

    const textContainerClass = classnames('messages__item-text', {
      'messages__item-text-scrollable': horizontalScroll,
    })

    const actionsClass = classnames('messages__item-actions', styles.actions)

    // TODO add emojis here
    const formattedMsgText = { __html: linkifyHtml(newLineToBr(msgParts[0])) }

    return (
      <>
        <div className={msgClassName} style={{ overflow: 'initial' }}>
          <div>
            <i className='messages__item-image'>
              <img src={avatar} alt={name} />
            </i>
            <span className='messages__item-author'>{cutText(name, 100)}</span>

            <span className='messages__item-time'>{time}</span>
          </div>

          {text && (
            <div className='messages__item-content messages__item--grouped'>
              {(canDelete || (isStaffUser(user) && !outbound)) && (
                <div className={actionsClass}>
                  <SelectCustom
                    options={this.getOptions()}
                    onChange={opt => opt.handler()}
                  />
                </div>
              )}
              <div
                className={textContainerClass}
                ref={textContainer => {
                  this.textContainer = textContainer
                }}
              >
                <span dangerouslySetInnerHTML={formattedMsgText} />
                {msgParts[1] && (
                  <HideableMessagePart
                    t={t}
                    text={msgParts[1]}
                    toggleMessage={toggleMessage(uuid)}
                    messageOpened={messageOpened}
                  />
                )}
              </div>

              <div className='messages__item-like-container'>
                <Like
                  count={likeCount}
                  marked={liked}
                  onClick={this.handleLike}
                />
                <Like
                  dislike
                  count={dislikeCount}
                  marked={disliked}
                  onClick={this.handleDislike}
                />
                <div className='messages__item-time messages__item-time--highlight'>
                  {time}
                </div>
              </div>
            </div>
          )}

          <div style={{ position: 'relative' }}>
            {!text && (canDelete || isStaffUser(user)) && (
              <div className={actionsClass} style={{ display: 'block' }}>
                <SelectCustom
                  options={this.getOptions()}
                  onChange={opt => opt.handler()}
                />
              </div>
            )}
            <MessageAttachments
              files={files}
              canEdit={canEdit}
              handleDeleteFile={this.handleDeleteFile}
              scope={scope}
              object={object}
              outbound={outbound}
            />
          </div>
        </div>
        {modal}
      </>
    )
  }
}

const mapStateToProps = state => ({
  locale: state.translations.translations.locale,
  user: getUser(state),
})

export default compose(
  withTranslation('Chatroom'),
  connect(mapStateToProps)
)(ChatroomMessage)
