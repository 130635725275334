// @flow

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import { get } from 'lodash-es'
import { useSelector } from 'react-redux'

import { getUser } from '../../../../utils/commonSelectors'
import Nbsp from '../../../NBSP'
import NewSelectAsync from '../../../NewSelectAsync'
import api from '../../../../core/api'
import {
  isDwellerWithOneFlat,
  isStaffUser,
  isDwellerUser,
} from '../../../../utils/utils'

import styles from '../RequestInfo.module.scss'
import ownerAddressStyles from './OwnerAddress.module.scss'

type Props = {
  building: Object,
  flat: Object,
  flatError: boolean,
  onChange: Object => void,
  outbound?: string,
  owner: Object,
}

const Editor = (props: Props) => {
  const { building, flat, owner, outbound } = props

  const { t } = useTranslation('Request')

  const user = useSelector(getUser)

  const ownerId = owner ? owner.id : null

  const flatParams = useMemo(() => {
    return {
      building: get(building, 'id'),
      owner: ownerId,
    }
  }, [building])

  if (owner && isDwellerWithOneFlat(owner)) {
    const flatNumber = get(flat, 'number')
    const ownerAddress = get(building, 'address')

    return (
      <div className='bar__submit bar__row bar-row-multi-user request-owner'>
        <div className='request-owner-label'>
          {t('Address')}:
          <Nbsp />
        </div>
        <div className={styles.addressSection}>{ownerAddress}</div>
        {flatNumber && (
          <div className={styles.flatSection}>
            {`${t('Flat')} ${flatNumber}`}
          </div>
        )}
      </div>
    )
  }

  const buildingParams = Object.assign(
    {},
    owner && isStaffUser(owner)
      ? undefined
      : {
          owner: owner && isDwellerUser(owner) ? null : ownerId,
          dweller: owner && isDwellerUser(owner) ? ownerId : null,
        }
  )

  const isAddressEditable = () => {
    return ownerId || isStaffUser(user)
  }

  const sectionClassName = classnames(ownerAddressStyles.section)

  const handleChangeBuilding = building => {
    props.onChange({
      building,
      flat: null,
    })
  }

  const handleChangeFlat = flat => props.onChange({ flat })

  const getLabel = option => option.address_obj?.value || option.address

  return (
    <div className='bar__submit bar__row bar-row-multi-user request-owner'>
      <div className={sectionClassName}>
        <div className='request-owner-label'>
          {t('Address')}:
          <Nbsp />
        </div>
        <NewSelectAsync
          pageSize={10}
          delay={700}
          view='dropdown'
          searchKey='address'
          className={ownerAddressStyles.select}
          api={params =>
            api.building.getBuildingOptions({ outbound, ...params })
          }
          disabled={!isAddressEditable()}
          getLabel={getLabel}
          getSelectedLabel={getLabel}
          placeholder={t('Select:selectAddress')}
          selectedItems={building ? [building] : []}
          permanentParams={buildingParams}
          onClear={() => handleChangeBuilding()}
          onClick={handleChangeBuilding}
        />
      </div>
      <div className={sectionClassName}>
        <div className='request-owner-label'>
          {t('Flat')}:
          <Nbsp />
        </div>
        <NewSelectAsync
          delay={700}
          pageSize={10}
          view='dropdown'
          searchKey='number_icontains'
          className={ownerAddressStyles.select}
          api={params => api.flat.getListTiny({ outbound, ...params })}
          disabled={!building}
          minSearchLength={1}
          getLabel={flat => flat.number}
          getSelectedLabel={flat => flat.number}
          placeholder={t('Select:selectFlat')}
          selectedItems={flat ? [flat] : []}
          dynamicParams={flatParams}
          onClear={() => handleChangeFlat()}
          onClick={handleChangeFlat}
        />
      </div>
    </div>
  )
}

export default Editor
