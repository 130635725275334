// @flow

import {
  postApi,
  getApi,
  patchApi,
  patchRaw,
  postRaw,
  deleteApi,
} from './api.core'

export const getRequest = (id: number) => getApi(`api/request/${id}/`)
export const getSharedRequest = hash => getApi(`api/shared-request/${hash}/`)
export const getListTiny = params => getApi('api/request-tiny/', params)
export const getArchivedListTiny = params =>
  getApi('api/archived/request-tiny/', params)
export const getTypes = ({ outbound, ...params }) =>
  getApi(`api/${outbound ? `id2id/${outbound}/` : ''}request-category/`, params)

export const getFilter = params => getApi('api/request-single-field/', params)
export const getArchivedFilter = params =>
  getApi('api/archived/request-single-field/', params)

export const createRequest = ({ outbound, ...params }) =>
  postRaw(`api/${outbound ? `id2id/${outbound}/` : ''}request/`, params)
export const updateRequest = (id, params) =>
  patchApi(`api/request/${id}/`, params)
export const updateRequestRaw = (id, params) =>
  patchRaw(`api/request/${id}/`, params)
export const getLogs = (id: number, params: Object) =>
  getApi(`api/request/${id}/log/`, params)
export const getMembers = id => getApi(`api/request/${id}/widget_members/`)
export const getChecklists = id => getApi(`api/request/${id}/widget_checklist/`)
export const attachMessage = params =>
  postApi(`api/request/${params.requestId}/copy_messages/`, params)

export const getRequestThreadInfo = (id, uuid) =>
  getApi(`api/request/${id}/email-thread/${uuid}/`)
export const getRequestThreads = (id, params) =>
  getApi(`api/request/${id}/email-thread/`, params)
export const createEmailMessage = (id, params) =>
  postRaw(`api/request/${id}/email-message/`, params)
export const getThreadMessage = (id, params) =>
  getApi(`api/request/${id}/email-message/`, params)
export const readThread = (id, uuid) =>
  postApi(`api/request/${id}/email-thread/${uuid}/read/`)
export const unreadThread = (id, uuid) =>
  postApi(`api/request/${id}/email-thread/${uuid}/unread/`)
export const patchThread = (id, uuid, params) =>
  patchRaw(`api/request/${id}/email-thread/${uuid}/`, params)
export const deleteThread = (requestId, threadUuid) =>
  deleteApi(`api/request/${requestId}/email-thread/${threadUuid}/`)
export const deleteMessage = (requestId, messageUuid) =>
  deleteApi(`api/request/${requestId}/email-message/${messageUuid}/`)
export const moveThread = (uuid, oldRequestId, newRequestId) =>
  postRaw(
    `api/request/${oldRequestId}/email-thread/${uuid}/move/${newRequestId}/`
  )

export const getRequestList = params => getApi('api/request-list/', params)
export const getFlatRequestList = params =>
  getApi('api/flat-request-list/', params)
export const getBuildingRequestList = params =>
  getApi('api/building-request-list/', params)
export const getProfileRequestList = params =>
  getApi('api/user-request-list', params)
export const getProfileRequestListCount = params =>
  getApi('api/user-request-list/count/', params)
export const getArchivedRequestList = params =>
  getApi('api/archived/request-list/', params)

export const getRequestListManagers = params =>
  getApi('api/request-list/managers/', params)
export const getArchivedRequestListManagers = params =>
  getApi('api/archived/request-list/managers/', params)

export const getRequestListLabels = params =>
  getApi('api/request-list/labels/', params)
export const getArchivedRequestListLabels = params =>
  getApi('api/archived/request-list/labels/', params)

export const getRequestListLight = params =>
  getApi('api/request-list-light/', params)
export const getRequestListExtra = params =>
  getApi('api/request-extra-info/', params)
export const getRequestListCount = params =>
  getApi('api/request-list-light/count/', params)
export const getArchivedRequestListLight = params =>
  getApi('api/archived/request-list-light/', params)
export const getArchivedRequestListCount = params =>
  getApi('api/archived/request-list-light/count/', params)

export const getExportRequestFields = () =>
  getApi('api/request-export/fields-list/')
export const exportRequests = params => postRaw('api/request-export/', params)

export const exportAsFiles = params =>
  postRaw('api/request-export-as-zip-files/', params)
export const exportAsFilesTask = taskId =>
  getApi(`api/request-export-as-zip-files-task/${taskId}`)

export const getRelatedRequestList = (requestId, params) =>
  getApi(`api/user-request-list/${requestId}/related-requests/`, params)
export const getUnrelatedRequestList = (requestId, params) =>
  getApi(`api/user-request-list/${requestId}/unrelated-requests/`, params)
export const getUnrelatedRequestListCount = (requestId, params) =>
  getApi(`api/user-request-list/${requestId}/unrelated-requests/count/`, params)
export const getRelatedRequestListCount = (requestId, params) =>
  getApi(`api/user-request-list/${requestId}/related-requests/count/`, params)
export const connectRequest = (requestId, params) =>
  postRaw(`api/request/${requestId}/connect/`, params)
export const disconnectRequest = (requestId, params) =>
  postRaw(`api/request/${requestId}/disconnect/`, params)

export const getContractors = params =>
  getApi('api/request-list/contractors/', params)
export const getArchivedContractors = params =>
  getApi('api/archived/request-list/contractors/', params)

export const getRequestOptions = params =>
  getApi('api/request-list-options/', params)
