// @flow

import { getApi, postApi, postRaw } from './api.core'

const getSessionHeader = uuid => ({
  WORKFLOWSESSION: uuid,
})

export const initWizard = ({ outbound, ...params }): Promise<Object> =>
  postRaw(
    `api/${outbound ? `id2id/${outbound}/` : ''}workflow/wizard/init/`,
    params
  )
export const dryRun = (
  workflowId: number,
  { outbound, ...params }
): Promise<Object> =>
  postRaw(
    `api/${
      outbound ? `id2id/${outbound}/` : ''
    }workflow/wizard/${workflowId}/dry-run/`,
    params
  )
export const prolong = (uuid: string, outbound?: string): Promise<Object> =>
  postApi(
    `api/${outbound ? `id2id/${outbound}/` : ''}workflow/wizard/prolong/`,
    null,
    getSessionHeader(uuid)
  )
export const getChunk = (uuid: string, outbound?: string): Promise<Object> =>
  getApi(
    `api/${outbound ? `id2id/${outbound}/` : ''}workflow/wizard/chunk/`,
    null,
    getSessionHeader(uuid)
  )
export const sendPayloadChunk = (uuid, payload, outbound) =>
  postApi(
    `api/${outbound ? `id2id/${outbound}/` : ''}workflow/wizard/chunk/`,
    payload,
    getSessionHeader(uuid)
  )
export const publish = workflow => postApi(`api/workflow/${workflow}/publish/`)
