// @flow
/* eslint-disable */

import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { parse } from 'qs'

import { MARKETPLACE_ROUTES } from './constants'

// core pages
import NotFound from './containers/NotFoundPage'
import DownloadDoc from './components/DownloadDoc'

// no-auth routes
import FreeRegistration from './components/Auth/Registration'
import CompanyRegistration from './components/CompanyRegistration'
import Login from './components/Auth/Login'
import Recovery from './components/Auth/Recovery'
import ConfirmEmail from './containers/ConfirmEmail'
import DocumentTemplates from './containers/DocumentTemplates'
import BuildingTab from './components/BuildingTab'
import BuildingGallery from './components/BuildingGallery'
import BuildingCreate from './components/BuildingCreate'
import BuildingView from './components/BuildingView'
import BuildingGroupView from './components/BuildingGroupView'
import PostView from './components/PostView'
import PostCreate from './components/Post/PostCreate'
import FilesTab from './components/FilesTab'
import DwellerFiles from './components/DwellerFiles'
import Profile from './containers/Profile'
import IndexPage from './components/IndexPage'
import RequestGallery from './components/RequestGallery'
import RequestView from './containers/RequestView'
import RequestCreate from './components/RequestCreate'
import CompanyView from './components/CompanyView'
import FlatView from './containers/FlatView'
import FAQ from './components/FAQ'
import Residents from './components/Residents'
import RequestConstructor from './containers/RequestConstructor'
import MarketplaceSettings from './containers/MarketplaceSettings'
import SettingsIntegrations from './containers/Integrations'
import SettingsPersonal from './components/SettingsPersonal'
import SettingsCorporate from './components/SettingsCorporate'
import DomondaIntegration from './components/DomondaIntegration'
import EmailIntegration from './components/EmailIntegration'
import EmailIntegrationNew from './components/EmailIntegrationNew'
import EmailIntegrationNewEdit from './components/EmailIntegrationNew/EmailIntegrationNewEdit'
import CorporateEmailIntegrationEdit from './components/EmailIntegration/CorporateEmailIntegrationEdit'
import PrivateEmailIntegrationEdit from './components/EmailIntegration/PrivateEmailIntegrationEdit'
import PrivateEmailIntegrationCreate from './components/EmailIntegration/PrivateEmailIntegrationCreate'
import CorporateEmailIntegrationCreate from './components/EmailIntegration/CorporateEmailIntegrationCreate'
import EmailIntegrationEdit from './components/EmailIntegration/EmailIntegrationEdit'
import ObjectsAndFields from './components/ObjectsAndFields'
import SettingsPermissions from './components/SettingsPermissions'
import SettingsUsers from './containers/SettingsUsers'
import Friends from './components/Friends'
import ImportFiles from './components/ImportFiles'
import ImportMap from './containers/ImportMap/ImportMap'
import widgets from './containers/widgets/DefaultWidgets'
import FeedbackWidgets from './containers/widgets/FeedbackWidgets'
import profileWidgets from './containers/widgets/ProfileWidgets'
import ChatroomWidgets from './containers/widgets/ChatroomWidgets'
import MailWidgets from './containers/widgets/MailWidgets'
import RequestWidgets from './containers/widgets/RequestWidgets'
import PostWidgets from './containers/widgets/PostWidgets'
import UkWidgets from './containers/widgets/UkWidgets'
import Messages from './containers/Messages/Messages'
import MailSection from './containers/Mail/MailSection'
import MailThread from './containers/Mail/MailThread/MailThread'
import NewMailThread from './containers/Mail/MailThread/NewMailThread'
import Thread from './components/Emails/Thread'
import Chatroom from './containers/Chatroom/Chatroom'
import SharedRequest from './components/SharedRequest/'
import PublicBuildingPage from './containers/PublicBuildingPage'
import Feedback from './containers/Feedback'
import Users from './components/Users'
import WorkflowList from './components/WorkflowList'
import Marketplace from './components/Marketplace'

import FavoriteProviders from './containers/ProvidersPage/FavoriteProviders'
import AllProviders from './containers/ProvidersPage/AllProviders'
import ProviderProfilePage from './containers/ProviderProfilePage'
import CreateProviderPage from './containers/CreateProviderPage'

import Offers from './containers/Offers'
import OfferProfilePage from './containers/OfferProfilePage'

import Orders from './containers/Orders'
import OrderProfilePage from './containers/OrderProfilePage'
import CreateOrderPage from './containers/CreateOrderPage'

import withMarketplace from './hocs/withMarketplace'
import addRedirectToProfile from './hocs/addRedirectToProfile'

import PublicRoute from './routes/PublicRoute'
import ThreeColumnRoute from './routes/ThreeColumnRoute'
import TwoColumnRoute from './routes/TwoColumnRoute'
import MarketplaceColumnRoute from './routes/MarketplaceColumnRoute'
import LogoutRoute from './routes/LogoutRoute'
import DocumentMapping from './components/DocumentMapping'
import MappedDocument from './components/MappedDocument'
import PasswordReset from './components/Auth/PasswordReset'
import PasswordSetting from './components/Auth/PasswordSetting/PasswordSetting'
import EmailConfirm from './components/Auth/EmailConfirm'
import RemoveProfileConfirm from './components/Auth/RemoveProfileConfirm'
import FlatList from './components/Flat/FlatList'
import TemplatesTab from './components/TemplatesTab'
import RequestsTab from './components/RequestsTab'
import BudgetPlan from './components/BudgetPlan'
import Pipelines from './components/Pipelines'
import Pipeline from './components/Pipeline'
import Automate from './components/Automate/Automate'
import AutomateView from './components/Automate/AutomateView'
import Activity from './components/Activities/ActivityWrapper'
import RequestWrapper from './components/Request/RequestWrapper'
import ConnectedRequests from './components/Request/ConnectedRequests'
import BuildingGroups from './components/BuildingGroups'
import Contractors from './components/Contractors'
import CreateContractorPage from './components/Contractors/CreateContractorPage'
import ContractorView from './components/Contractors/ContractorView'
import MailMessage from './components/MailMessage'
import IdwellToIdwell from './components/IdwellToIdwell'
import InboundCompanyProfile from './components/ConnectedCompanies/InboundCompanyProfile'
import OutboundCompanyProfile from './components/ConnectedCompanies/OutboundCompanyProfile'

export default store => {
  const downloadFile = ({ location: { search } }) => {
    const url = parse(search, { ignoreQueryPrefix: true }).file

    if (!url) {
      return
    }

    window.open(url)

    return <Redirect to='/' />
  }

  return (
    <Switch>
      <ThreeColumnRoute
        exact
        path='/'
        widgets={widgets}
        component={props => <IndexPage {...props} key={props.location.state} />}
      />
      <ThreeColumnRoute
        exact
        path='/newsbands/search/:search'
        component={IndexPage}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/news_band'
        component={IndexPage}
        widgets={widgets}
      />
      <ThreeColumnRoute
        path='/profile/:profileId/:tab?/:folderId?/'
        component={Profile}
      />
      <ThreeColumnRoute
        path='/external-contact/:profileId/:tab?/:folderId?/'
        component={Profile}
      />
      <ThreeColumnRoute
        exact
        path='/chatroom/:chatroomUuid'
        component={Chatroom}
        widgets={ChatroomWidgets}
      />
      <ThreeColumnRoute
        exact
        path='/mail/:uuid'
        component={NewMailThread}
        widgets={MailWidgets}
      />
      <Route
        exact
        path='/mail/:uuid/print/:muuid?'
        component={props => <NewMailThread {...props} print />}
      />
      <ThreeColumnRoute
        exact
        path='/message/:uuid'
        component={MailMessage}
        widgets={props => <MailWidgets {...props} isSingle/>}
      />
      <Route
        exact
        path='/request/:requestId/emails/:uuid/print/:muuid?'
        component={props => <Thread {...props} print />}
      />
      <ThreeColumnRoute
        exact
        path='/building/create/'
        component={BuildingCreate}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/building/:buildingId/:tab?/:folderId?/'
        component={BuildingView}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/building-group/:groupId/:tab?/:folderId?/'
        component={BuildingGroupView}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/request/create'
        component={RequestCreate}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/post/create'
        component={PostCreate}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/post/:id'
        component={PostView}
        widgets={PostWidgets}
      />
      <ThreeColumnRoute
        exact
        path='/post/:id/:mode'
        component={PostView}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/flat/:flatId/:tab?/:folderId?/'
        component={FlatView}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/uk'
        component={CompanyView}
        widgets={UkWidgets}
      />
      <ThreeColumnRoute
        exact
        path='/settings/personal'
        component={SettingsPersonal}
      />
      <ThreeColumnRoute
        exact
        path='/settings/integrations'
        component={SettingsIntegrations}
      />
      <ThreeColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/corporate'
        component={SettingsCorporate}
      />
      <ThreeColumnRoute
        exact
        path='/settings/email/:tab?/'
        component={EmailIntegration}
      />
      <ThreeColumnRoute
        exact
        path='/settings/email/private/create'
        component={PrivateEmailIntegrationCreate}
      />
      <ThreeColumnRoute
        exact
        path='/settings/email/corporate/create'
        component={CorporateEmailIntegrationCreate}
      />
      <ThreeColumnRoute
        exact
        path='/settings/email/private/:id'
        component={PrivateEmailIntegrationEdit}
      />
      <ThreeColumnRoute
        exact
        path='/settings/email/corporate/:id'
        component={CorporateEmailIntegrationEdit}
      />
      <TwoColumnRoute
        exact
        path='/settings/email-integration/'
        component={EmailIntegrationNew}
      />
      <ThreeColumnRoute
        exact
        path='/settings/email-integration/:id'
        component={EmailIntegrationNewEdit}
      />
      <ThreeColumnRoute
        exact
        path='/request/:requestID/feedback/:id'
        widgets={FeedbackWidgets}
        component={Feedback}
      />
      <ThreeColumnRoute
        exact
        allowWorker
        path='/request/:requestID/:tab([^\d]*)?/:folderId([\d]*)?/'
        component={RequestView}
        widgets={RequestWidgets}
      />
      <TwoColumnRoute
        exact
        path='/messages'
        component={props => <Messages {...props} key={props.location.state} />}
      />
      <TwoColumnRoute
        exact
        allowAdminManager
        path='/mails/:tab?/'
        component={MailSection}
      />
      <TwoColumnRoute
        exact
        path='/dwellers-files/:folderId?/'
        component={DwellerFiles}
      />
      <TwoColumnRoute
        exact
        allowAdminManager
        path='/files/:tab?/:folderId?/'
        component={FilesTab}
      />
      <TwoColumnRoute
        exact
        allowAdminManager
        path='/document-mapping/'
        component={DocumentMapping}
      />
      <ThreeColumnRoute
        exact
        allowAdminManager
        path='/document-mapping/:mappedDocumentId/'
        component={MappedDocument}
      />
      <TwoColumnRoute
        exact
        allowAdminManager
        path='/mails/search/:search'
        component={MailSection}
      />
      <TwoColumnRoute
        exact
        path='/buildings/search/:search'
        component={BuildingGallery}
      />
      <TwoColumnRoute
        exact
        path='/groups'
        component={BuildingGroups}
      />
      <TwoColumnRoute
        exact
        path='/buildings'
        component={props => (
          <BuildingGallery {...props} key={props.location.state} />
        )}
      />
      <Route
        exact
        path='/buildings/buildings'
        render={({ location })  => <Redirect to={`/buildings/${location?.search || ''}`} /> }
      />
      <Route
        exact
        path='/buildings/groups'
        render={({ location })  => <Redirect to={`/groups/${location?.search || ''}`} /> }
      />
      <TwoColumnRoute
        exact
        allowAdminManager
        path='/contractors'
        component={Contractors}
      />
      <ThreeColumnRoute
        exact
        allowAdminManager
        path='/contractor/create'
        component={CreateContractorPage}
      />
      <ThreeColumnRoute
        exact
        path='/contractor/:contractorId/:tab?/:folderId?/'
        component={ContractorView}
        widgets={widgets}
      />
      <TwoColumnRoute
        exact
        path='/friends'
        component={props => <Friends {...props} key={props.location.state} />}
      />
      <TwoColumnRoute
        exact
        path='/flats'
        component={props => <FlatList {...props} key={props.location.state} />}
      />
      <TwoColumnRoute
        exact
        path='/flats/search/:search'
        component={props => <FlatList {...props} key={props.location.state} />}
      />
      <TwoColumnRoute exact path='/residents' component={Residents} />
      <Route
        exact
        path='/requests/services'
        render={({ location })  => <Redirect to={`/requests/${location?.search || ''}`} /> }
      />
      <Route
        exact
        path='/requests/activities'
        render={({ location })  => <Redirect to={`/activities/${location?.search || ''}`} /> }
      />
      <TwoColumnRoute exact path='/requests' component={RequestWrapper} />
      <TwoColumnRoute exact path='/connected_requests' component={ConnectedRequests} />
      <ThreeColumnRoute exact path='/connected_requests/:outbound/:id' component={ConnectedRequests} />
      <ThreeColumnRoute
        exact
        path='/connected_request/create'
        component={RequestCreate}
        widgets={widgets}
      />
      <ThreeColumnRoute
        exact
        path='/connected_request/:outbound/:requestID/:tab([^\d]*)?/:folderId([\d]*)?/'
        component={RequestView}
        widgets={RequestWidgets}
      />
      <TwoColumnRoute exact path='/activities' component={Activity} />
      <ThreeColumnRoute
        exact
        allowAdminManager
        path='/settings/marketplace'
        component={MarketplaceSettings}
      />
      <TwoColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/workflows'
        component={WorkflowList}
      />
      <TwoColumnRoute
        exact
        allowAdminManager
        path='/settings/templates/:tab?/'
        component={TemplatesTab}
      />
      <TwoColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/idwell-to-idwell/'
        component={IdwellToIdwell}
      />
      <ThreeColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/inbound/:uuid'
        component={InboundCompanyProfile}
      />
      <ThreeColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/outbound/:uuid'
        component={OutboundCompanyProfile}
      />
      <ThreeColumnRoute
        exact
        path='/settings/doc-templates'
        component={DocumentTemplates}
      />
      <TwoColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/workflow/:workflowId'
        component={RequestConstructor}
      />
      <ThreeColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/permissions/:tab?/'
        component={SettingsPermissions}
      />
      <TwoColumnRoute exact path='/settings/import' component={ImportFiles} />
      <TwoColumnRoute
        exact
        path='/settings/domonda'
        component={DomondaIntegration}
      />
      <TwoColumnRoute
        exact
        allowOnlyAdmin
        path='/settings/fields/:tab?/'
        component={ObjectsAndFields}
      />
      <TwoColumnRoute
        allowOnlyAdmin
        path='/settings/users/:tab?/'
        component={SettingsUsers}
      />
      <TwoColumnRoute
        exact
        path='/requests/search/:search'
        component={RequestGallery}
      />
      <TwoColumnRoute
        exact
        path='/settings/import/map/:importId'
        component={ImportMap}
      />
      <TwoColumnRoute exact allowOnlyAdmin path='/settings/automate/profile/:id/:tab?/' component={AutomateView} />
      <TwoColumnRoute exact allowOnlyAdmin path='/settings/automate/:tab?/' component={Automate} />
      <TwoColumnRoute exact path='/settings/pipeline' component={Pipelines} />
      <TwoColumnRoute
        exact
        path='/settings/pipeline/create'
        component={Pipeline}
      />
      <TwoColumnRoute
        exact
        path='/settings/pipeline/:pipelineId'
        component={Pipeline}
      />
      <TwoColumnRoute exact path='/faq' component={FAQ} />
      <TwoColumnRoute exact allowAdminManager path='/users' component={Users} />
      <TwoColumnRoute exact allowAdminManager path='/users/search/:search' component={Users} />
      <Route exact path='/download' render={downloadFile} />
      <Route exact path='/document/:fileId/download' component={DownloadDoc} />
      <Route
        exact
        path='/settings'
        render={() => <Redirect to='/settings/personal' />}
      />
      <PublicRoute
        path='/registration/:hash'
        component={PasswordSetting}
        needLogout
      />
      <PublicRoute path='/free-registration' component={FreeRegistration} />
      <Route
        exact
        path='/company-registration'
        component={CompanyRegistration}
      />
      <PublicRoute
        exact
        path='/recover/:hash'
        component={PasswordReset}
        needLogout
      />
      <PublicRoute exact path='/login' component={Login} />
      <PublicRoute exact path='/recover' component={Recovery} />
      <Route
        exact
        path='/logout'
        render={() => <LogoutRoute store={store} />}
      />
      <PublicRoute
        exact
        path='/confirm-email/:hash'
        component={EmailConfirm}
        needLogout
      />
      <PublicRoute
        exact
        path='/confirm-remove-profile/:token'
        component={RemoveProfileConfirm}
        needLogout
      />
      <Route exact path='/shared-request/:hash' component={SharedRequest} />
      <Route
        path='/public/building/:buildingId'
        component={PublicBuildingPage}
      />
      {/* Marketplace */}
      <TwoColumnRoute
        exact
        path={'/marketplace/:tab?/'}
        component={withMarketplace(Marketplace)}
      />
      <ThreeColumnRoute
        exact
        allowAdminManager
        path={MARKETPLACE_ROUTES['marketplaceCreateProvider']}
        component={withMarketplace(CreateProviderPage)}
      />
      <MarketplaceColumnRoute
        exact
        path='/marketplace/provider/:id'
        component={withMarketplace(ProviderProfilePage)}
      />
      <MarketplaceColumnRoute
        exact
        path='/marketplace/offer/:id'
        component={withMarketplace(OfferProfilePage)}
      />
      <ThreeColumnRoute
        exact
        path={MARKETPLACE_ROUTES.marketplaceCreateOrder}
        component={withMarketplace(CreateOrderPage)}
      />
      <MarketplaceColumnRoute
        exact
        path='/marketplace/order/:id'
        component={withMarketplace(OrderProfilePage)}
      />
      <TwoColumnRoute exact path='/budget-plan/:id?/' component={BudgetPlan} />
      <Route
        exact
        path='/search/profile/email/:email/'
        component={addRedirectToProfile(Redirect)}
      />
      <Route
        exact
        path='/search/profile/phone/(00|[\+]*):phone/'
        component={addRedirectToProfile(Redirect)}
      />
      <Route component={NotFound} />
    </Switch>
  )
}
